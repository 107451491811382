@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --content-appear-time: .75s;
}
body:not([class*="fp-viewing"]) .videos,
body:not([class*="fp-viewing"]) #fp-nav {
  display: none !important;
}
body .footerlnks {
  display: none;
}
body:not([class*="fp-viewing"]) .footerlnks,
body.fp-viewing-8 .footerlnks {
  display: flex;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white
}
.gradientText{
  color: #020ED2;
  background-color: #020ED2;
  background-image: linear-gradient(93.77deg, #034EEC -50%, #D8459A 100%);
  -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradientText2{
  color: #020ED2;
  background-color: #020ED2;
  background-image: linear-gradient(93.77deg, #ffffff -10%, #f864ba 50%);
  -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fullpage-wrapper .section {
  padding-top: 6rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  padding-left: 14.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: hidden; */
}
@media (min-width: 992px) {
  .fullpage-wrapper .section {
    height: 100vh;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  background: #08051f;
  font-size: .8333333333vw;
}
@media screen and (max-width: 1920px) {
  html {
      font-size:.9333333333vw;
  }
}

@media screen and (max-width: 1440px) {
  html {
      font-size:1.1111111111vw
  }
}

@media screen and (max-width: 1080px) {
  html {
      font-size:1.1111111111vw
  }
}

@media screen and (max-width: 576px) {
  html {
      font-size:4.1025641026vw
  }
}
.fluid-ball {
  position: absolute;
  width: 120vw;
  height: 120vh;
  top: -5vw;
  left: -5vh;
  z-index: 1;
  mix-blend-mode: screen;
  pointer-events: none;
  transition: transform 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}
.smallVideo {
  z-index: 1;
  transform: translate3d(-15vw, 25vh, 0px) scale(0.3);
}
.smallVideo3 {
  transform: translate3d(-15vw, 25vh, 0px) scale(0)
}
body.fp-viewing-1 .bigCircle {
  z-index: 0;
  transform: translate3d(0vw, 0vh, 0px) scale(1);
}
body.fp-viewing-1 .smallVideo {
  z-index: 1;
  transform: translate3d(-15vw, 25vh, 0px) scale(0.3);
}
body.fp-viewing-1 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-2 .bigCircle {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(1);
}
body.fp-viewing-2  .smallVideo {
  z-index: 1;
  transform: translate3d(-50vw,60vh,0) scale(1.2);
}
body.fp-viewing-2 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-3-0 .bigCircle,
body.fp-viewing-3-1 .bigCircle,
body.fp-viewing-3-2 .bigCircle {
z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(0);
}
body.fp-viewing-3-0 .smallVideo,
body.fp-viewing-3-1 .smallVideo,
body.fp-viewing-3-2 .smallVideo {
  z-index: 1;
  transform: translate3d(-70vw,0,0) scale(1);
}
body.fp-viewing-3-0 .smallVideo3,
body.fp-viewing-3-1 .smallVideo3,
body.fp-viewing-3-2 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-5 .bigCircle {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-5 .smallVideo {
  z-index: 0;
  transform: translate3d(0vw, 0vh, 0px) scale(1.2);
}
body.fp-viewing-5 .smallVideo3 {
  z-index: 1;
  transform: translate3d(-15vw, 25vh, 0px) scale(0.3);
}
body.fp-viewing-6 .bigCircle {
  z-index: 0;
  transform: translate3d(25vw, 25vh, 0px) scale(0);
}
body.fp-viewing-6   .smallVideo {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(1);
}
body.fp-viewing-6 .smallVideo3 {
  z-index: 1;
  transform: translate3d(-70vw, 0vh, 0px) scale(1);
}
body.fp-viewing-7 .bigCircle {
  z-index: 1;
  transform: translate3d(25vw, 30vh, 0px) scale(0.25);
}
body.fp-viewing-7   .smallVideo {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(0);
}
body.fp-viewing-7 .smallVideo3 {
  z-index: 0;
  transform: translate3d(40vw, 0vh, 0px) scale(1);
}
body.fp-viewing-8 .bigCircle {
  z-index: 1;
  transform: translate3d(30vw, 0vh, 0px) scale(1);
}
body.fp-viewing-8 .smallVideo {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-8 .smallVideo3 {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(1);
}
body.fp-viewing-9 .bigCircle {
  z-index: 1;
  transform: translate3d(-70vw, 0vh, 0px) scale(1);
}
body.fp-viewing-9   .smallVideo {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-9 .smallVideo3 {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(0);
}
body.fp-viewing-10 .bigCircle {
  z-index: 1;
  transform: translate3d(5vw, 0vh, 0px) scale(0.8);
}
body.fp-viewing-10   .smallVideo {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-10 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-12 .bigCircle {
  z-index: 0;
  transform: translate3d(0vw, 0vh, 0px) scale(1.2);
}
body.fp-viewing-12   .smallVideo {
  z-index: 1;
  transform: translate3d(-15vw, 25vh, 0px) scale(0.3);
}
body.fp-viewing-12 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
body.fp-viewing-13 .bigCircle {
  z-index: 0;
  transform: translate3d(0vw, -200vh, 0px) scale(1);
}
body.fp-viewing-13   .smallVideo {
  z-index: 1;
  transform: translate3d(-70vw, 0vh, 0px) scale(1);
}
body.fp-viewing-13 .smallVideo3 {
  z-index: 0;
  transform: translate3d(-15vw, 25vh, 0px) scale(0);
}
._1 canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
._7:before{
  background: url(./assets/images/blur-6.png) no-repeat;
  background-size: contain;
  background-position: 50%;
  height: 100%;
  min-width: 100%;
  right: 0;
  left: 0;
}
._7:after {
  content: "";
  display: block;
  position: absolute;
  background: url(./assets/images/bg-ring.png) no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 220%;
  height: 220%;
  left: 15%;
  top: -14%;
  z-index: -1;
}
.section:before,
.section:after {
  content: "";
  display: block;
  position: absolute;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 150vh;
  height: 150vh;
  opacity: 0;
  pointer-events: none;
  transition: var(--content-appear-time) opacity;
  z-index: 1;
}
._3:before {
  background-image: url(./assets/images/overlay-1.png);
  left: -75vh;
  top: -25vh;  
}
._2:after {
  background-image: url(./assets/images/overlay-2.png);
  right: -50vh;
  top: -25vh;
  border-radius: 50%;
}
._4:after {
  background-image: url(./assets/images/overlay-3.png);
  right: -50vh;
  top: -20vh;
}
.banner-title:before, .banner-title:after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  background: rgba(255,255,255,0.01);
}
.banner-title:before {
  width: var(--width);
  height: var(--height);
  --width: 6.125rem;
  --height: var(--width);
  top: calc(var(--width) / -3);
  left: calc(var(--height) / -2);
  box-shadow: inset 0 -0.5rem .875rem rgba(255,255,255,0.25);
}
.banner-title:after {
  width: var(--width);
  height: var(--height);
  --width: 9.25rem;
  --height: var(--width);
  right: 0;
  bottom: calc(var(--height) / -3);
  box-shadow: inset 0 4px 4px rgba(255,255,255,0.25);
}
.section.fp-completely:before,
.section.fp-completely:after {
    opacity: .7;
}
.section._11:before {
    background: url(./assets/images/blur-11.png) no-repeat;
    background-size: contain;
    background-position: 50%;
    height: 200vh;
    min-width: 200vh;
    right: 0;
    left: 0;
    opacity: 1;
    transition: calc(var(--content-appear-time) + .5s) opacity;
}
.fp-overflow {
  overflow: visible !important;
}
.text-stroke {
  -webkit-text-stroke: 1px rgba(255,255,255,0.25);
  pointer-events: none;
}
.blur-5 video {
  mix-blend-mode: screen;
}
.blur-5:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #08051f;
  z-index: -3;
}
.hybriDexCircle .fp-overflow:after {
  content: "";
  display: block;
  position: absolute;
  background: url(./assets/images/blur-5.png) no-repeat;
  background-size: contain;
  background-position: 50%;
  width: 150%;
  height: 150%;
  left: -25%;
  top: -20%;
  opacity: .7;
  z-index: -2;
}

.circles .circle2:before {
  content: "";
  display: block;
  position: absolute;
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%,#fff 0,#3c314a 100%);
  left: calc(50% - 2vh);
  top: calc(50% - 2vh);
  -webkit-animation: orbit2Rotation1 30s linear infinite;
  animation: orbit2Rotation1 30s linear infinite
}
.blur-bg {
  background: hsla(0, 0%, 100%, .01);
  box-shadow: inset -10px 14px 20px hsla(0, 0%, 100%, .08);
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(44px);
}
@media screen and (max-width: 576px) {
  .circles .circle2:before {
      -webkit-animation:orbit2Rotation1Mob 30s linear infinite;
      animation: orbit2Rotation1Mob 30s linear infinite
  }
}
.circles .circle2:after {
  content: "";
  display: block;
  position: absolute;
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%,#1b75a7 0,#330867 100%);
  filter: blur(6px);
  left: calc(50% - 1.5vh);
  top: calc(50% - 1.5vh);
  -webkit-animation: orbit2Rotation2 30s linear infinite;
  animation: orbit2Rotation2 30s linear infinite
}

@media screen and (max-width: 576px) {
  .circles .circle2:after {
      -webkit-animation:orbit2Rotation2Mob 30s linear infinite;
      animation: orbit2Rotation2Mob 30s linear infinite
  }
}
.circles .circle3:before {
  content: "";
  display: block;
  position: absolute;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%,#6664dd 0,#43206d 100%);
  left: calc(50% - 3vh);
  top: calc(50% - 3vh);
  -webkit-animation: orbit3Rotation 50s linear infinite;
  animation: orbit3Rotation 50s linear infinite
}

@media screen and (max-width: 576px) {
  .circles .circle3:before {
      -webkit-animation:orbit3RotationMob 50s linear infinite;
      animation: orbit3RotationMob 50s linear infinite
  }
}
@-webkit-keyframes orbit2Rotation1 {
  0% {
      transform: rotate(0deg) translateX(-30vh)
  }

  to {
      transform: rotate(1turn) translateX(-30vh)
  }
}

@keyframes orbit2Rotation1 {
  0% {
      transform: rotate(0deg) translateX(-30vh)
  }

  to {
      transform: rotate(1turn) translateX(-30vh)
  }
}

@-webkit-keyframes orbit2Rotation2 {
  0% {
      transform: rotate(0deg) translateX(30vh)
  }

  to {
      transform: rotate(1turn) translateX(30vh)
  }
}

@keyframes orbit2Rotation2 {
  0% {
      transform: rotate(0deg) translateX(30vh)
  }

  to {
      transform: rotate(1turn) translateX(30vh)
  }
}

@-webkit-keyframes orbit3Rotation {
  0% {
      transform: rotate(0deg) translateX(37.5vh)
  }

  to {
      transform: rotate(1turn) translateX(37.5vh)
  }
}

@keyframes orbit3Rotation {
  0% {
      transform: rotate(0deg) translateX(37.5vh)
  }

  to {
      transform: rotate(1turn) translateX(37.5vh)
  }
}
@-webkit-keyframes orbit2Rotation1Mob {
  0% {
      transform: rotate(0deg) translateX(-20vh)
  }

  to {
      transform: rotate(1turn) translateX(-20vh)
  }
}

@keyframes orbit2Rotation1Mob {
  0% {
      transform: rotate(0deg) translateX(-20vh)
  }

  to {
      transform: rotate(1turn) translateX(-20vh)
  }
}

@-webkit-keyframes orbit2Rotation2Mob {
  0% {
      transform: rotate(0deg) translateX(20vh)
  }

  to {
      transform: rotate(1turn) translateX(20vh)
  }
}

@keyframes orbit2Rotation2Mob {
  0% {
      transform: rotate(0deg) translateX(20vh)
  }

  to {
      transform: rotate(1turn) translateX(20vh)
  }
}

@-webkit-keyframes orbit3RotationMob {
  0% {
      transform: rotate(0deg) translateX(27.5vh)
  }

  to {
      transform: rotate(1turn) translateX(27.5vh)
  }
}

@keyframes orbit3RotationMob {
  0% {
      transform: rotate(0deg) translateX(27.5vh)
  }

  to {
      transform: rotate(1turn) translateX(27.5vh)
  }
}
.fp-watermark {
  display: none !important;
}
@media screen and (min-aspect-ratio: 97/71) {
  .tech-wrapper {
      font-size:.9rem;
  }
}

@media screen and (min-aspect-ratio: 485/309) {
  .tech-wrapper {
      font-size:.8rem;
  }
}

@media screen and (min-aspect-ratio: 97/53) {
  .tech-wrapper {
      font-size:.7rem;
  }
}

@media screen and (min-aspect-ratio: 485/234) {
  .tech-wrapper {
      font-size:.6rem;
  }
}

@media screen and (max-width: 576px) {
  .tech-wrapper {
      font-size:.8rem
  }
}

@media screen and (max-width: 576px)and (min-aspect-ratio:15/32) {
  .tech-wrapper {
      font-size:.7rem
  }
}

.blinkArrow {
  --color-path-1: hsla(0,0%,100%,0.3);
  --color-path-2: #fff;
  -webkit-animation: lighten 1.5s infinite;
  animation: lighten 1.5s infinite
}

.blinkArrow svg path {
  transition: fill .5s ease
}

@-webkit-keyframes lighten {
  0% {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }

  50% {
      --color-path-1: #fff;
      --color-path-2: hsla(0,0%,100%,0.3)
  }

  to {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }
}

@keyframes lighten {
  0% {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }

  50% {
      --color-path-1: #fff;
      --color-path-2: hsla(0,0%,100%,0.3)
  }

  to {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }
}
.gardientV {
  background: linear-gradient(to bottom,rgba(0,79,238,0) 0%,#004fee 23.96%,#ee4492 77.08%,rgba(238,68,146,0) 100%);
}
@media (max-width: 576px) {
  .gardientV {
    background: linear-gradient(to right,rgba(0,79,238,0) 0%,#004fee 23.96%,#ee4492 77.08%,rgba(238,68,146,0) 100%);
 }
}
.progress {
  --color-path-1: hsla(0,0%,100%,0.3);
  --color-path-2: hsla(0,0%,100%,0.3);
  --color-path-3: hsla(0,0%,100%,0.3);
  --color-path-4: hsla(0,0%,100%,0.3);
  --color-path-5: hsla(0,0%,100%,0.3);
  --color-path-6: hsla(0,0%,100%,0.3);
  -webkit-animation: progressLighten 2s infinite;
  animation: progressLighten 2s infinite;
}

.progress svg {
  position: absolute;
  width: 150vw;
  height: auto;
  top: -35vw;
  left: -35vw
}

.progress svg path {
  transition: fill .3s ease;
}

@media screen and (max-width: 1440px) {
  .progress svg {
      top:-30vw;
      left: -40vw
  }
}

@media screen and (max-width: 576px) {
  .progress svg {
    width: 310vw;
    height: auto;
    transform: rotate(90deg);
    left: -98vw;
    top: 25vw;
  }
}

@-webkit-keyframes progressLighten {
  0% {
      --color-path-1: #fff
  }

  15% {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }

  30% {
      --color-path-2: hsla(0,0%,100%,0.3);
      --color-path-3: #fff
  }

  45% {
      --color-path-3: hsla(0,0%,100%,0.3);
      --color-path-4: #fff
  }

  60% {
      --color-path-4: hsla(0,0%,100%,0.3);
      --color-path-5: #fff
  }

  75% {
      --color-path-5: hsla(0,0%,100%,0.3);
      --color-path-6: #fff
  }

  80% {
      --color-path-6: hsla(0,0%,100%,0.3)
  }

  to {
      --color-path-6: hsla(0,0%,100%,0.3)
  }
}

@keyframes progressLighten {
  0% {
      --color-path-1: #fff
  }

  15% {
      --color-path-1: hsla(0,0%,100%,0.3);
      --color-path-2: #fff
  }

  30% {
      --color-path-2: hsla(0,0%,100%,0.3);
      --color-path-3: #fff
  }

  45% {
      --color-path-3: hsla(0,0%,100%,0.3);
      --color-path-4: #fff
  }

  60% {
      --color-path-4: hsla(0,0%,100%,0.3);
      --color-path-5: #fff
  }

  75% {
      --color-path-5: hsla(0,0%,100%,0.3);
      --color-path-6: #fff
  }

  80% {
      --color-path-6: hsla(0,0%,100%,0.3)
  }

  to {
      --color-path-6: hsla(0,0%,100%,0.3)
  }
}
._5 .wrapper {
  --wrapper-height: 70vh;
  height: var(--wrapper-height);
  overflow: hidden;
}
.circles {
  --orbit-size: var(--wrapper-height) + 5vh;
}
.circles [class*=circle] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid rgba(255,255,255,0.1);
  border-radius: 50%;
  z-index: -1;
}
.circles .circle1 {
  width: calc(var(--orbit-size) - 30vh);
  height: calc(var(--orbit-size) - 30vh);
}
.circles .circle2 {
  width: calc(var(--orbit-size) - 15vh);
  height: calc(var(--orbit-size) - 15vh);
}
.circles .circle3 {
  width: calc(var(--orbit-size));
  height: calc(var(--orbit-size));
  z-index: 3;
}
.architecture .row-first .box, .architecture .row-second .box {
  background: rgba(251,199,212,0.1);
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.2);
  padding: 1.25rem 3rem;
  text-wrap: nowrap;
  line-height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @apply font-pjBold text-xl;
}
.architecture .row-first .box:last-child, .architecture .row-second .box:last-child {
  background: rgba(251,199,212,0.1);
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.2);
  text-wrap: nowrap;
  line-height: 1.75rem;
  @apply font-pjRegular text-base;
}
@media screen and (max-width: 576px) {
  .fullpage-wrapper .section {
    padding: 4rem 2rem 2rem;
    position: relative;
    z-index: 2;
  }
  body.fp-viewing-5.modalOpen .fullpage-wrapper .section {
    z-index: 5;
  }
  body.fp-viewing-5.modalOpen .hybriDexCircle .fp-overflow:after {
    display: none;
  }
  body.fp-viewing-5.modalOpen .fluid-ball {
    z-index: 2 !important;
  }
  .fluid-ball {
    width: 170vw;
    left: -35vw;
    top: 0;
  }
  .smallVideo {
    width: 170vw;
    left: -35vw;
    top: 0;
  }
  .banner-title:before {
    --width: 2.3125rem;
  }
  .banner-title:after {
    --width: 3.375rem;
  }
  body.fp-viewing-1 .smallVideo {
    transform: translate3d(-25vw, 10vh, 0px) scale(0.3);
  }
  body.fp-viewing-3 .bigCircle {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(0);
  }
  body.fp-viewing-3 .smallVideo {
      z-index: 1;
      transform: translate3d(0vw, 45vh, 0px) scale(1);
  }
  body.fp-viewing-3 .smallVideo3 {
      z-index: 0;
      transform: translate3d(-25vw, 10vh, 0px) scale(0);
  }
  body.fp-viewing-4 .bigCircle {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(0);
  }
  body.fp-viewing-4 .smallVideo {
      z-index: 1;
      transform: translate3d(0vw, -60vh, 0px) scale(1.2);
  }
  body.fp-viewing-4 .smallVideo3 {
      z-index: 0;
      transform: translate3d(-25vw, 10vh, 0px) scale(0);
  }
  body.fp-viewing-5 .bigCircle {
      z-index: 0;
      transform: translate3d(-25vw, 10vh, 0px) scale(0);
  }
  body.fp-viewing-5 .smallVideo {
      z-index: 0;
      transform: translate3d(0vw, -15vh, 0px) scale(1.4);
  }
  body.fp-viewing-5 .smallVideo3 {
      z-index: 1;
      transform: translate3d(-25vw, 5vh, 0px) scale(0.3);
  }
  body.fp-viewing-6 .bigCircle {
      z-index: 0;
      transform: translate3d(-25vw, 10vh, 0px) scale(0);
  }
  body.fp-viewing-6 .smallVideo {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(1);
  }
  body.fp-viewing-6 .smallVideo3 {
      z-index: 1;
      transform: translate3d(0vw, -65vh, 0px) scale(1.2);
  }
  body.fp-viewing-7 .bigCircle {
      z-index: 1;
      transform: translate3d(-25vw, 5vh, 0px) scale(0.3);
  }
  body.fp-viewing-7 .smallVideo {
      z-index: 0;
    transform: translate3d(0vw, -200vh, 0px) scale(0);
  }
  body.fp-viewing-7 .smallVideo3 {
      z-index: 0;
      transform: translate3d(0vw, -15vh, 0px) scale(1.4);
  }
  body.fp-viewing-8 .bigCircle {
      z-index: 1;
      transform: translate3d(0vw, -65vh, 0px) scale(1.2);
  }
  body.fp-viewing-8 .smallVideo {
      z-index: 0;
    transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-8 .smallVideo3 {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(1);
  }
  body.fp-viewing-9 .bigCircle {
      z-index: 1;
      transform: translate3d(25vw, -45vh, 0px) scale(1);
  }
  body.fp-viewing-9 .smallVideo {
      z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-9 .smallVideo3 {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(0);
  }
  body.fp-viewing-10 .bigCircle {
      z-index: 1;
      transform: translate3d(30vw, -50vh, 0px) scale(1.4);
  }
  body.fp-viewing-10 .smallVideo {
      z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-10 .smallVideo3 {
      z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-11 .bigCircle {
      z-index: 0;
      transform: translate3d(30vw, -10vh, 0px) scale(2.2);
  }
  body.fp-viewing-11 .smallVideo {
      z-index: 1;
      transform: translate3d(-10vw, 22vh, 0px) scale(0.3);
  }
  body.fp-viewing-11 .smallVideo3 {
      z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-12 .bigCircle {
      z-index: 0;
    transform: translate3d(0vw, -200vh, 0px) scale(2.2);
  }
  body.fp-viewing-12 .smallVideo {
      z-index: 1;
      transform: translate3d(0vw, -65vh, 0px) scale(1.2);
  }
  body.fp-viewing-12 .smallVideo3 {
     z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  body.fp-viewing-13 .bigCircle {
      z-index: 0;
      transform: translate3d(0vw, -200vh, 0px) scale(0);
  }
  body.fp-viewing-13 .smallVideo {
      z-index: 1;
      transform: translate3d(30vw, -55vh, 0px) scale(1.4);
  }
  body.fp-viewing-13 .smallVideo3 {
      z-index: 0;
      transform: translate3d(-15vw, 25vh, 0px) scale(0);
  }
  ._5 .wrapper {
    --wrapper-height: 60vh;
  }
  ._7 {
    padding-bottom: 0 !important;
  }
    ._7 .fp-overflow {
      height: 100vh;
      padding-top: 3rem;
  }
    .architecture .box {
      margin: 0 !important;
      min-width: auto;
  }
}
.section .learnmore {
  opacity: 0;
  transition: var(--content-appear-time) opacity .25s ease;
}
.section .divider {
  max-width: 0;
  transition: max-width var(--content-appear-time) ease;
}
.section.fp-completely .learnmore {
  opacity: 1;
}
.section.fp-completely .divider {
  max-width: 6.125rem;
}
._1 h1.banner-title {
  opacity: 0;
  transition: opacity var(--content-appear-time);
}
._1 h1.banner-title span:first-child {
  transform: translate3d(15rem,0,0);
  transition: transform var(--content-appear-time) ease;
}
._1 h1.banner-title span:last-child {
  transform: translate3d(25rem,0,0);
  transition: transform var(--content-appear-time) ease;
}
._1 .message:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 0;
  background: #fff;
  margin-right: 1.5rem;
  pointer-events: none;
  transition: var(--content-appear-time) height .3s ease;
}
._1 .message p {
  transform: translate3d(0,5rem,0);
  opacity: 0;
  transition: var(--content-appear-time) transform .3s ease, var(--content-appear-time) opacity .5s;
}
._1.fp-completely h1.banner-title {
  opacity: 1;
}
._1.fp-completely h1.banner-title span {
  transform: translateZ(0);
}
._1.fp-completely .message:before {
  height: 100%;
}
._1.fp-completely .message p {
  transform: translateZ(0);
  opacity: 1;
}
.section .one, .section .two, .section .three, .section .four, .section .five, .section .six {
  transform: translate3d(0,5rem,0);
  opacity: 0;
}
.section .leftAnimation {
  transform: translate3d(-5rem,0,0);
  opacity: 0;
}
.section .one {
  transition: var(--content-appear-time) transform .1s, var(--content-appear-time) opacity .1s;
}
.section .two {
  transition: var(--content-appear-time) transform .2s, var(--content-appear-time) opacity .2s;
}
.section .three {
  transition: var(--content-appear-time) transform .3s, var(--content-appear-time) opacity .3s;
}
.section .four {
  transition: var(--content-appear-time) transform .4s, var(--content-appear-time) opacity .4s;
}
.section .five {
  transition: var(--content-appear-time) transform .5s, var(--content-appear-time) opacity .5s;
}
.section .six {
  transition: var(--content-appear-time) transform .6s, var(--content-appear-time) opacity .6s;
}
.section .leftAnimation {
  transition: var(--content-appear-time) transform .1s, var(--content-appear-time) opacity .1s;
}
.section.fp-completely .one, .section.fp-completely .two, .section.fp-completely .three, .section.fp-completely .four, .section.fp-completely .five, .section.fp-completely .six,.section.fp-completely .leftAnimation {
  transform: translateZ(0);
  opacity: 1;
}
div#fp-nav {
  display: flex;
  flex-direction: row;
  left: 3.5rem !important;
  top: 0;
  transform: none;
  bottom: 0;
  min-width: 30px;
}
div#fp-nav::after {
  content: "";
  position: absolute;
  top: 26.15rem;
  right: -15px;
  margin: auto;
  width: 2px;
  height: 1.25rem;
  background: #fff;
}
div#fp-nav ul {
  transition: all .8s ease;
  position: relative;
}
body.fp-viewing-1 div#fp-nav ul {
  top: 20rem;  
}
body.fp-viewing-2 div#fp-nav ul {
  top: 5.9rem;
}
body.fp-viewing-3-0 div#fp-nav ul,
body.fp-viewing-3-1 div#fp-nav ul,
body.fp-viewing-3-2 div#fp-nav ul {
  top: -7.8rem;
}
body.fp-viewing-5 div#fp-nav ul {
  top: -18.8rem;
}
body.fp-viewing-6 div#fp-nav ul {
  top: -35.55rem;
}
body.fp-viewing-7 div#fp-nav ul {
  top: -46.65rem;
}
body.fp-viewing-8 div#fp-nav ul {
  top: -61.5rem;
}
body.fp-viewing-9 div#fp-nav ul {
  top: -69.5rem;
}
body.fp-viewing-10 div#fp-nav ul {
  top: -88.15rem;
}
body.fp-viewing-12 div#fp-nav ul {
  top: -103.6rem;
}
body.fp-viewing-13 div#fp-nav ul {
  top: -117.2rem;
}
#fp-nav ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  will-change: transform;
  contain: layout;
  gap: 6.25rem;
  flex-direction: column;
}
#fp-nav ul li {
  height: initial !important;
  width: initial !important;
  margin: initial !important;
}
#fp-nav ul li a {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
#fp-nav ul li .fp-tooltip {
  opacity: 0.3 !important;
  overflow: visible !important;
  position: initial !important;
  width: auto !important;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-family: manifold !important;
  transition: all 2s ease;
  transform: rotate(180deg) scale(.8);
  font-size: 1.125rem !important;
  letter-spacing: .1em;
}
#fp-nav ul li a.active+.fp-tooltip {
  opacity: 1 !important;
  transform: rotate(180deg) scale(1);
}
.fp-arrow {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  transition: var(--content-appear-time) transform .2s, var(--content-appear-time) opacity .2s;
  opacity: 0;
}
.section.fp-completely .fp-arrow {
  opacity: 1 !important;
}
.fp-arrow.fp-prev {
  background: url(./assets/icons/angle-left-solid.svg) no-repeat center / contain;
  left: -60px;
}
.fp-arrow.fp-next {
  background: url(./assets/icons/angle-right-solid.svg) no-repeat center / contain;
  right: -60px;
}
.accordion:hover .accordion-item:hover .accordion-item-content,
.accordion .accordion-item--default .accordion-item-content {
    height: auto
}
.accordion-item-content,
.accordion:hover .accordion-item-content {
    height: 0;
    overflow: hidden;
    transition: height .25s;
}

.pageactive{
  color: red;
}
body.modalOpen .fullpage-wrapper {
  z-index: 1111;
}
.marketModal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(93.77deg, #034EEC -50%, #D8459A 100%);
  z-index: 11;
  padding: 2rem;
}
.marketModal:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  z-index: -1;
}
.marketModal {
  z-index: 99;
}
@media (max-width: 576px) {
  #fp-nav {
    display: none !important;
  }
  .fp-arrow {
    display: none;
  }
  .marketModal {
    padding: 1.5rem;
  }
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.8);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  margin: auto;
  
}
.modal .modal-inner {
  background: linear-gradient(93.77deg, #034EEC -50%, #D8459A 100%);
  padding: 6rem;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .fp-viewing-7 .fluid-ball  {
    z-index: initial !important;
  }
}
.dropdown {
  position: relative;
}
.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(255, 255, 255, 1);
  padding: 5px 20px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  min-width: 200px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
      transition: all .4s ease;
}
.dropdown-menu li {
  margin: 4px 0;
}
.dropdown-menu li a:hover {
  color: #000
}